import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/SEO";

const useStyles = makeStyles(theme => ({
  heroContainer: {
    backgroundColor: theme.palette.common.light
  },
  heroTitle: {
    marginBottom: theme.spacing(3)
  }
}));

const GeneralTemplate = ({ data }) => {
  const classes = useStyles();
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.description} />
      <Section className={classes.heroContainer}>
        <Container maxWidth="md">
          <Typography className={classes.heroTitle} variant="h1">
            {frontmatter.title}
          </Typography>
          <Typography>{frontmatter.description}</Typography>
        </Container>
      </Section>
      <Section gutters={[4, 0, 10]}>
        <Container maxWidth="md">
          <Grid item dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
      </Section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
      }
    }
  }
`;

export default GeneralTemplate;
